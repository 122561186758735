import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { SessionStorageKeys, useLocalization, useMixpanelEvents } from 'hooks';
import {
  getOrigin,
  getSubDomain,
  useSessionStorage,
} from '@guider-global/front-end-utils';

import { URLQueryParams } from '@guider-global/shared-types';
import { useAuth } from '@guider-global/auth-hooks';
import { getIncompleteProfileFields } from 'utils';
import { useOrganization } from '@guider-global/sanity-hooks';
import { LoadingElement } from 'components';
import { datadogLogs } from '@guider-global/datadog';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';
import moment from 'moment-timezone';
import {
  useProfiles,
  useSettings,
  useUsers,
} from '@guider-global/front-end-hooks';

export const LoginPage: FC = () => {
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const auth0Error = urlSearchParams.get('error') ?? undefined;
  const auth0ErrorMessage =
    urlSearchParams.get('error_description') ?? undefined;

  // Auth
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth({});

  // Utils
  const organizationSlug = getSubDomain();

  // Hooks
  // useSessionStorage
  const [redirect, setRedirect] = useSessionStorage(
    SessionStorageKeys.REGISTER_REDIRECT,
  );
  useMixpanelEvents({ navigationEventName: 'Login' });

  // Users
  const { users = [], isLoadingUsers, errorsUsers } = useUsers({});
  const isUsers = users?.length > 0;
  const user = users.at(0);

  // Profiles
  const { isLoadingProfiles, profile, errorsProfiles } = useProfiles({
    options: {
      keepPreviousData: false,
    },
  });

  // Settings
  const { settings, isLoadingSettings, createSettings, updateSettings } =
    useSettings({});
  const setting = settings?.at(0);

  // Sanity organization
  const { organization: sanityOrganization } = useOrganization({
    organizationSlug,
  });

  const organizationId = sanityOrganization?.basic_info?.auth0_organization_id;

  // Sanity localization
  const { localeCode } = useLocalization(organizationSlug);

  // Agreements
  const additionalAgreements =
    sanityOrganization?.white_label?.additional_agreements;

  // Events
  const incompleteProfileFields = useMemo(() => {
    if (!profile || !sanityOrganization) return [];
    return getIncompleteProfileFields({
      profile,
      sanityOrganization,
      organizationAgreements: additionalAgreements,
    });
  }, [additionalAgreements, profile, sanityOrganization]);

  useEffect(() => {
    // If URL Query param "redirect" exists, set within session storage
    const register = urlSearchParams.get(URLQueryParams.REDIRECT);
    if (!register) return;
    setRedirect(register);
  }, [setRedirect, urlSearchParams]);

  const loadingResources =
    isLoadingProfiles || isLoadingUsers || isLoadingSettings || isAuthLoading;

  const errors = Boolean(
    errorsProfiles || errorsUsers || auth0Error || auth0ErrorMessage,
  );
  useEffect(() => {
    const createOrUpdateSettings = async () => {
      const timezone = moment.tz.guess(true);

      if (!profile || !localeCode) return;

      if (!setting) {
        await createSettings({
          profile: profile.id,
          localeCode,
          timezone,
        });
      } else if (!setting.timezone) {
        await updateSettings(setting.id, { localeCode, timezone });
      } else if (setting.localeCode !== localeCode) {
        await updateSettings(setting.id, { localeCode });
      }
    };

    const pageUrl = new URL(window.location.href);

    if (errors || !organizationId) return;

    if (!isAuthLoading && !isAuthenticated) {
      const handleRedirect = async (
        organization: string,
        redirectUri: string,
      ) => {
        try {
          await loginWithRedirect({
            authorizationParams: {
              organization,
              redirect_uri: redirectUri,
            },
          });
        } catch (err) {
          console.error('Error occurred with Redirect', err);
        }
      };
      const state = pageUrl.searchParams.get('state') ?? undefined;
      const stateUrl = (url: string) => {
        try {
          return new URL(url);
        } catch {
          return undefined;
        }
      };
      const samlRedirectUrl = state ? stateUrl(state) : undefined;
      if (samlRedirectUrl) {
        const redirectPathName = samlRedirectUrl.pathname;
        setRedirect(redirectPathName);
        handleRedirect(organizationId, `${pageUrl.origin}/login`);
        return;
      }

      const organization =
        urlSearchParams.get('organization') ?? organizationId;
      const organizationName = urlSearchParams.get('organization_name');
      const redirectUri = organizationName
        ? `${pageUrl.protocol}//${organizationName}.${pageUrl.hostname}/login`
        : `${pageUrl.origin}/login`;
      handleRedirect(organization, redirectUri);
      return;
    }

    if (!isUsers && isAuthLoading) return;

    if (loadingResources) return;

    if ((!profile || !profile?.isOnboarded) && isUsers) {
      navigate('/register');
      return;
    }

    if (profile?.isOnboarded && incompleteProfileFields.length > 0) {
      navigate('/complete-profile');
      return;
    }

    if (redirect && profile?.isOnboarded) {
      sessionStorage.removeItem(SessionStorageKeys.REGISTER_REDIRECT);
      try {
        const fullUrl = new URL(redirect as string);
        if (fullUrl) {
          window.location.href = fullUrl.href;
        }

        return;
      } catch (_error) {
        navigate(redirect);
        return;
      }
    }
    if (!redirect && profile && profile?.isOnboarded) {
      navigate('/dashboard');
      createOrUpdateSettings();
    }
  }, [
    createSettings,
    errors,
    incompleteProfileFields.length,
    isAuthLoading,
    isAuthenticated,
    isUsers,
    loadingResources,
    localeCode,
    loginWithRedirect,
    navigate,
    organizationId,
    profile,
    redirect,
    setRedirect,
    setting,
    updateSettings,
    urlSearchParams,
  ]);

  useEffect(() => {
    const handleShowAppError = (
      errorCode?: string | number,
      errorMessage?: string,
    ) => {
      const code = errorCode ?? 'Undefined Error Code';
      const message = errorMessage ?? 'Undefined Error Message';
      console.error('error', { code, message });
      datadogLogs.logger.error('loginPage:', { code, message });
      dispatch(
        showAppAlert({
          severity: 'error',
          message: message,
          timeout: 10000,
        }),
      );
    };

    if (auth0Error || auth0ErrorMessage) {
      console.error('ERROR', { auth0Error, auth0ErrorMessage });
      handleShowAppError(auth0Error, auth0ErrorMessage);
      navigate('/');
    }

    if (errorsProfiles) {
      const code = errorsProfiles?.code;
      const message = errorsProfiles.message;
      logout({
        logoutParams: {
          returnTo: getOrigin(),
        },
      });

      handleShowAppError(code, message);
    }
    if (errorsUsers) {
      const code = errorsUsers?.code;
      const message = errorsUsers.message;
      logout({
        logoutParams: {
          returnTo: getOrigin(),
        },
      });

      handleShowAppError(code, message);
    }
  }, [
    auth0Error,
    auth0ErrorMessage,
    dispatch,
    errorsProfiles,
    errorsUsers,
    logout,
    navigate,
  ]);

  useEffect(() => {
    user && mixpanel.identify(user.email);
  }, [user]);

  return <LoadingElement />;
};
